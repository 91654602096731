export default {
  data () {
    return {
      translationReportPath: 'reports.performance.split_expenses_report.'
    }
  },
  methods: {
    fullName (person, onlyFirstname = false) {
      if (!person.firstname && !person.lastname) {
        return person.id
      } else if ((person.firstname && onlyFirstname) || (person.firstname && !person.lastname)) {
        return person.firstname
      } else if (!person.firstname && person.lastname) {
        return person.lastname
      } else {
        return person.firstname + ' ' + person.lastname
      }
    }
  }
}
