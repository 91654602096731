<template>
  <b-container fluid class="balance_expenses_transaction_table-wrapper px-0">
    <b-row class="" no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPath + 'title') }}</h2>
          </template>

          <b-col class="col-md-8">
            <p>{{ $t( translationPath + 'description') }}</p>
          </b-col>

          <b-col class="px-0 mb-5">
            <b-table class="spirecta-simple-table split-expenses-table split-expenses-distributed-expenses-transactions-table" show-empty hover responsive striped foot-clone
              stacked="md"
              :items="rows"
              :current-page="currentPage"
              :per-page="perPage"
              :fields="fieldValues"
              :busy="busy || !loaded"
               :empty-text="$t(translationPath + 'table.empty_text',{period_start: loaded ? this.period.start : '', period_end: loaded ? this.period.end : ''}) "
            >

              <template v-slot:table-busy>
                <loader/>
              </template>

              <template v-slot:head(is_checked)>
                <b-form-checkbox v-model="allSelected" @change="onAllSelectedChange"></b-form-checkbox>
              </template>
              <template v-slot:foot(is_checked)>
                <b-form-checkbox class="ml-2" v-model="allSelected" @change="onAllSelectedChange"></b-form-checkbox>
              </template>

              <template v-slot:cell(title)="row">
                <b-link :to="'/transactions/view/transaction/' + row.item.id">{{ row.value }}</b-link>
              </template>

              <template v-slot:cell(amount)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
              </template>

              <template v-slot:cell(account)="row">
                <b-link :to="'/reports/performance/accounts/'+row.item.account_id+'/view'">{{ row.item.account }}</b-link>
              </template>

              <template v-for="(person, index) in (apiData !== null ? apiData.persons : [])" :slot='"cell(person_"+person.id+"_should_pay)"' slot-scope='row'>
                <span :key="index">
                  {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
                </span>
              </template>

              <template v-slot:cell(is_checked)="row">
                <b-form-checkbox v-model="row.item.is_checked" @change="onItemSelectedChange"></b-form-checkbox>
              </template>
            </b-table>
            <pagination v-if="rows.length" :totalRows="rows.length" :currentPage.sync="currentPage" :perPage.sync="perPage"></pagination>
            <b-row v-if="rows.length" class="mb-5 mt-4">
              <b-col class="pt-4 pt-md-1">
                <b-button
                  v-if="isAnySelected"
                  type="submit"
                  variant="danger"
                  class="float-right ml-3 px-4 btn-save"
                  :disabled="apiButtonsDisabled"
                  @click="() => onDelete()"
                >{{ $t(translationPath + 'delete_selected') }}</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-card>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import formatAmount from '@/assets/filters/formatAmount'
import Loader from '@/components/common/Loader'
import pagination from '@/components/common/pagination'
import SplitExpensesTableMixin from './SplitExpensesTableMixin'

export default {
  name: 'SplitExpensesDistributedExpensesTableTransactions',
  components: { Loader, pagination },
  props: ['api-data', 'loaded', 'period'],
  mixins: [SplitExpensesTableMixin],
  filters: { formatAmount },
  data () {
    return {
      translationPath: 'reports.performance.split_expenses_report.tab.distributed_expenses.transactions.',
      busy: true,
      rows: [],
      allSelected: false,
      apiButtonsDisabled: false,
      currentPage: 1,
      perPage: 50
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    fieldValues () {
      const fields = [
        { key: 'is_checked', label: '' },
        { key: 'date', label: this.$t('common.date'), class: 'td-date' },
        { key: 'title', label: this.$t('common.title'), class: 'td-title' },
        { key: 'account', label: this.$t('common.category'), class: 'td-account' }
      ]

      if (this.apiData === null) {
        return fields
      }

      this.apiData.persons.map((person) => {
        fields.push({
          key: 'person_' + person.id + '_should_pay',
          label: this.$t(this.translationPath + 'person_should_pay', { person_name: this.fullName(person, true) }),
          class: 'td-percentage text-nowrap text-right'
        })
        fields.push({
          key: 'person_' + person.id + '_should_pay_percentage',
          label: this.$t(this.translationPath + '_should_pay_percentage', { person_name: this.fullName(person, true) }),
          class: 'text-right text-nowrap'
        })
      })

      fields.push({ key: 'amount', label: this.$t('common.total'), class: 'td-amount text-right' })

      return fields
    },
    isAnySelected () {
      return this.rows.filter(row => row.is_checked).length
    }
  },
  methods: {
    fillTable () {
      if (this.apiData === null || !this.currentCOA.locale) {
        return false
      }

      this.busy = true
      this.apiButtonsDisabled = true
      this.rows = []
      for (const transactionId in this.apiData.transactions_balanced) {
        const transaction = this.apiData.transactions_balanced[transactionId]
        const row = {
          id: transactionId,
          is_checked: false,
          title: transaction.title,
          date: transaction.date,
          amount: transaction.amount,
          account: transaction.expense_account_title,
          account_id: transaction.expense_account_id,
          should_pay_sum: 0
          // paid_sum: 0
        }

        if (transaction.person_percentages) {
          for (const j in transaction.person_percentages) {
            const person = transaction.person_percentages[j]
            row['person_' + person.person_id + '_should_pay'] = parseFloat(person.amount_should_pay)
            row['person_' + person.person_id + '_should_pay_percentage'] = parseFloat(person.percentage_should_pay).toFixed(0) + ' %'

            row.should_pay_sum += parseFloat(person.percentage_should_pay)
          }
        }
        this.rows.push(row)
      }
      this.rows.sort((a, b) => a.date < b.date ? -1 : 1)

      this.busy = false
      this.apiButtonsDisabled = false
    },
    amountPerPersonText (item, suffix) {
      let str = ''
      this.apiData.persons.map((person) => {
        let personAmount = item.amount * (item['person_' + person.id + suffix] / 100)
        personAmount = formatAmount(personAmount, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, false, 0)
        str += personAmount + ' / '
      })
      return str.substr(0, str.length - 2)
    },
    onAllSelectedChange (val) {
      this.rows.map((row) => {
        row.is_checked = val
      })
    },
    onItemSelectedChange (val) {
      let totalSelected = this.rows.filter((row) => row.is_checked).length

      if (val) {
        totalSelected++
      } else {
        totalSelected--
      }

      if (this.rows.length === totalSelected) {
        this.allSelected = true
      } else {
        this.allSelected = false
      }
    },
    onDelete (row) {
      row = row || false
      this.apiButtonsDisabled = true
      let rowsChecked = null
      if (row) {
        row.is_checked = true
        rowsChecked = [row]
      } else {
        rowsChecked = this.rows.filter((row) => row.is_checked)
      }

      if (!rowsChecked.length) {
        this.$bvToast.toast(this.$t(this.translationReportPath + 'toast.rows_are_not_selected'), {
          title: this.$t(this.translationReportPath + 'title'),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
        this.apiButtonsDisabled = false
        return false
      }

      const ids = []
      rowsChecked.map((row) => {
        ids.push(parseInt(row.id))
      })
      axios.delete(`${process.env.VUE_APP_ROOT_API}/transactions/person-percentages-multiple`, { data: { transaction_ids: ids } })
        .then((response) => {
          this.$bvToast.toast(this.$t(this.translationReportPath + 'toast.transactions_delete_success'), {
            title: this.$t(this.translationReportPath + 'title'),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })

          if (row) {
            this.rows = this.rows.filter((item) => item.id !== row.id)
          } else {
            this.rows = this.rows.filter((item) => !item.is_checked)
          }

          this.$emit('data-updated')
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationReportPath + 'toast.transactions_delete_fail'), {
            title: this.$t(this.translationReportPath + 'title'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .then(() => {
          this.apiButtonsDisabled = false
        })

      this.apiButtonsDisabled = false
    },
    onUnbalance (row) {
      row = row || false
      this.apiButtonsDisabled = true
      let rowsChecked = null
      if (row) {
        row.is_checked = true
        rowsChecked = [row]
      } else {
        rowsChecked = this.rows.filter((row) => row.is_checked)
      }

      if (!rowsChecked.length) {
        this.$bvToast.toast(this.$t(this.translationReportPath + 'toast.rows_are_not_selected'), {
          title: this.$t(this.translationReportPath + 'title'),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
        this.apiButtonsDisabled = false
        return false
      }

      const data = {}
      rowsChecked.map((transaction) => {
        data[transaction.id] = {}
        this.apiData.persons.map((person) => {
          data[transaction.id][person.id] = {
            is_balanced: 0
          }
        })
      })

      axios.post(`${process.env.VUE_APP_ROOT_API}/transactions/person-percentages-multiple`, { data: data })
        .then((response) => {
          this.$bvToast.toast(this.$t(this.translationReportPath + 'toast.transaction_unbalanced_success'), {
            title: this.$t(this.translationReportPath + 'title'),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })

          if (row) {
            this.rows = this.rows.filter((item) => item.id !== row.id)
          } else {
            this.rows = this.rows.filter((item) => !item.is_checked)
          }

          this.$emit('data-updated')
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationReportPath + 'toast.transaction_unbalanced_fail'), {
            title: this.$t(this.translationReportPath + 'title'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .then(() => {
          this.apiButtonsDisabled = false
        })
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.fillTable()
      }
    },
    apiData: {
      deep: true,
      handler () {
        this.fillTable()
      }
    }
  },
  created () {
    this.fillTable()
  }
}
</script>

<style lang="scss">
.split-expenses-distributed-expenses-transactions-table
{
  td:nth-child(4),th:nth-child(4),
  td:nth-child(6),th:nth-child(6),
  td:nth-child(8),th:nth-child(8)
  {
    border-right:1px solid black;
  }
}
</style>
