<template>
  <div class="split-expenses-balance-expenses-table-wrapper">

    <split-expenses-balance-expenses-table-summary
      :api-data="apiData"
      :loaded="loaded"
    ></split-expenses-balance-expenses-table-summary>

    <split-expenses-balance-expenses-table-summary-detailed
      class="mt-4"
      :api-data="apiData"
      :loaded="loaded"
    ></split-expenses-balance-expenses-table-summary-detailed>

    <split-expenses-balance-expenses-table-transactions
      class="mt-4"
      :api-data="apiData"
      :loaded="loaded"
      :period="period"
      @data-updated="onDataUpdated"
    >
    </split-expenses-balance-expenses-table-transactions>

  </div>
</template>

<script>
import SplitExpensesBalanceExpensesTableSummary from './SplitExpensesBalanceExpensesTableSummary'
import SplitExpensesBalanceExpensesTableSummaryDetailed from './SplitExpensesBalanceExpensesTableSummaryDetailed'
import SplitExpensesBalanceExpensesTableTransactions from './SplitExpensesBalanceExpensesTableTransactions'

export default {
  name: 'SplitExpensesBalanceExpensesTableWrapper',
  props: {
    apiData: { default: [] },
    period: { default: null },
    loaded: { default: false }
  },
  components: {
    SplitExpensesBalanceExpensesTableSummary,
    SplitExpensesBalanceExpensesTableSummaryDetailed,
    SplitExpensesBalanceExpensesTableTransactions
  },
  data () {
    return {
    }
  },
  computed: {},
  methods: {
    onDataUpdated () {
      this.$emit('data-updated')
    },
    onNeedApiUpdate () {
      this.$emit('need-api-update')
    }
  }
}
</script>

<style lang="scss">

</style>
