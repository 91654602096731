<template>
  <b-container fluid class="split-expenses-summary-table px-0">
    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPath + 'title') }}</h2>
          </template>

          <b-col class="col-md-8">
            <p>{{ $t( translationPath + 'description') }}</p>
            <p>{{ $t( translationPath + 'description2') }}</p>
          </b-col>

          <b-col class="px-0 mb-5">
            <b-table class="spirecta-simple-table split-expenses-summary-table" show-empty hover responsive striped foot-clone
              stacked="md"
              :items="rows"
              :fields="fieldValues"
              :busy="busy || !loaded"
            >

              <template v-slot:table-busy>
                <loader/>
              </template>

              <template v-slot:head(title)="row">
                {{ $t(translationPath + row.label) }}
              </template>

              <template v-slot:cell(title)="row">
                {{ $t(translationPath + row.value) }}
              </template>

              <template v-for="(person, index) in (apiData !== null ? apiData.persons : [])" :slot='"cell(person_"+person.id+")"' slot-scope='row'>
                <span :key="index">
                  {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
                  <span v-if="row.index === 0"> ({{ row.item['person_' + person.id + '_percentage'] }} %)</span>
                </span>
              </template>
            </b-table>
          </b-col>
        </b-card>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import Loader from '@/components/common/Loader'
import SplitExpensesTableMixin from './SplitExpensesTableMixin'

export default {
  name: 'SplitExpensesBalanceExpensesTableSummary',
  props: ['api-data', 'loaded'],
  components: { Loader },
  mixins: [SplitExpensesTableMixin],
  filters: { formatAmount },
  data () {
    return {
      translationPath: 'reports.performance.split_expenses_report.tab.balance_expenses.summary.',
      busy: true,
      rows: []
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    fieldValues () {
      const fields = [
        { key: 'title', label: 'table.summary' }
      ]

      if (this.apiData === null) {
        return fields
      }

      this.apiData.persons.map((person) => {
        fields.push({ key: 'person_' + person.id, label: this.fullName(person, true), class: 'text-right' })
      })

      return fields
    }
  },
  methods: {
    fillTable () {
      if (this.apiData === null || !this.currentCOA.locale) {
        return false
      }

      this.busy = true
      this.rows = [
        { title: 'table.paid' },
        { title: 'table.should_pay' },
        { title: 'table.left_to_pay' },
        { title: 'table.explanation' }
      ]

      for (const personId in this.apiData.totals.transactions_not_balanced.persons) {
        const person = this.apiData.totals.transactions_not_balanced.persons[personId]
        this.rows[0]['person_' + personId] = person.total_sum_paid
        this.rows[0]['person_' + personId + '_percentage'] = person.total_sum_paid_percentage
        this.rows[1]['person_' + personId] = person.total_sum_should_pay
        this.rows[2]['person_' + personId] = person.total_sum_left_to_pay
        this.rows[3]['person_' + personId] = person.total_sum_left_to_pay < 0 ? this.$t('reports.performance.split_expenses_report.tab.balance_expenses.transactions.should_receive') + ' ' + (formatAmount(person.total_sum_left_to_pay * -1, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0)) : this.$t('reports.performance.split_expenses_report.tab.balance_expenses.transactions.should_pay') + ' ' + (formatAmount(person.total_sum_left_to_pay, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0))
      }

      this.busy = false
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.fillTable()
      }
    },
    apiData: {
      deep: true,
      handler () {
        this.fillTable()
      }
    }
  },
  created () {
    this.fillTable()
  }
}
</script>

<style lang="scss">
  .split-expenses-summary-table
  {
    thead tr th{
      border-top:0;
    }
    tr:nth-child(3) td{
      border-bottom: 2px solid black;
      border-top: 1px solid black;
    }

    tr:nth-child(4) td{
    }
  }
</style>
