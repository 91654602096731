<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link :disabled="disabled" class="main-gradient-header-tab-menu-tab-item" tab="split_expenses" :class="{selected: activeTab === 'split_expenses'}"  @click="onTabClick">
          {{ $t(translationPath + 'step1_split_expenses') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link :disabled="disabled" class="main-gradient-header-tab-menu-tab-item" tab="balance_expenses" :class="{selected: activeTab === 'balance_expenses'}"  @click="onTabClick">
          {{ $t(translationPath + 'step2_balance_expenses') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link :disabled="disabled" class="main-gradient-header-tab-menu-tab-item" tab="distributed_expenses" :class="{selected: activeTab === 'distributed_expenses'}"  @click="onTabClick">
          {{ $t(translationPath + 'distributed_expenses') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3"></span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'SplitExpensesSubmenu',
  props: {
    activeTab: {
      type: String,
      default: 'summary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      translationPath: 'reports.performance.split_expenses_report.submenu.'
    }
  },
  methods: {
    onTabClick () {
      this.$emit('tab-switched', event.target.attributes.tab.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/submenu.scss';
</style>
