<template>
  <div class="pagination-wrapper">
    <div class="d-flex  flex-column flex-md-row justify-content-between">
      <div class="d-flex flex-column flex-md-row mb-3 mb-md-0">
        <div v-if="totalRowsValue > 0" class="d-flex align-items-center justify-content-center">
          <span class="text-gray">{{ $t('common.pagination.showing')}} {{(currentPageValue - 1) * perPageValue + 1}} {{ $t('common.pagination.to')}} {{endRecord}} {{ $t('common.pagination.of')}} {{totalRowsValue}}</span>
        </div>
        <div v-if="totalRowsValue > 0" class="space-divider"></div>
        <div class="d-flex align-items-center justify-content-center show-per-page-wrapper">
          <span >{{ $t('common.pagination.show_per_page')}}: </span>
          <b-form-select :options="pageOptions" @change="updatePerPage($event)" v-model="perPageValue" class="selectPerPage" />
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center pagination-pages-wrapper">
        <b-pagination :total-rows="totalRowsValue" :per-page="perPageValue" :prev-text="$t('common.pagination.prev')" :next-text="$t('common.pagination.next')" @input="updateCurrentPage($event)" v-model="currentPageValue" class="my-0"/>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_SETTINGS_PAGINATION_ROWS_PER_PAGE } from '@/store/actions/settings'
import { mapState } from 'vuex'
export default {
  name: 'pagination',
  props: ['totalRows', 'currentPage', 'perPage', 'showAll'],
  data () {
    return {
      pageOptions: [10, 25, 50, 100, 250],
      currentPageValue: this.currentPage,
      perPageValue: this.perPage,
      defaultValuePerPage: 25
    }
  },
  mounted () {
    /* if (this.pageOptions[this.pageOptions.length - 1] > 250) {
      this.pageOptions.splice(this.pageOptions.length - 1, 1)
    } */
    if (this.showAll && this.totalRows && parseInt(this.totalRows) > 100 && (this.pageOptions).indexOf(this.totalRows) === -1) {
      this.pageOptions.push(this.totalRows)
    }
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'SET_SETTINGS_PAGINATION_ROWS_PER_PAGE') {
        this.perPageValue = mutation.payload
        this.$emit('update:perPage', mutation.payload)
      }
    })
  },
  beforeDestroy () {
    const defaultRowsPerPage = this.currentCOA && Object.prototype.hasOwnProperty.call(this.currentCOA, 'default_pagination_rows') && this.currentCOA.default_pagination_rows && this.currentCOA.default_pagination_rows > 0 ? this.currentCOA.default_pagination_rows : this.defaultValuePerPage
    const self = this
    self.$store.dispatch(SET_SETTINGS_PAGINATION_ROWS_PER_PAGE, defaultRowsPerPage)
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    statePagination () {
      return this.$store.state.settings.paginationRowsPerPage
    },
    totalRowsValue () {
      return this.totalRows
    },
    endRecord () {
      if (this.perPageValue * this.currentPageValue < this.totalRowsValue) {
        return this.perPageValue * this.currentPageValue
      } else {
        return this.totalRowsValue
      }
    }
  },
  methods: {
    updatePerPage ($event) {
      this.$emit('update:perPage', $event)
      this.$emit('update-per-page')
      this.$store.dispatch(SET_SETTINGS_PAGINATION_ROWS_PER_PAGE, $event)
    },
    updateCurrentPage ($event) {
      this.$emit('update:currentPage', $event)
      this.$emit('update-current-page')
    }
  },
  watch: {
    totalRows: function (newVal, oldVal) {
      if (this.pageOptions[this.pageOptions.length - 1] > 100) {
        this.pageOptions.splice(this.pageOptions.length - 1, 1)
      }
      if (this.showAll && this.totalRows && parseInt(this.totalRows) > 100 && (this.pageOptions).indexOf(this.totalRows) === -1) {
        this.pageOptions.push(this.totalRows)
      }
    }
  }
}
</script>
<style lang="scss">
  .pagination-wrapper{
    .text-gray{font-size:14px;color: #84888F;}
    .show-per-page-wrapper, .selectPerPage{font-size:14px;color: #84888F;}
    .pagination-pages-wrapper{font-size:14px;}
  }
</style>
