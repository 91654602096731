<template>
  <b-row v-show="isFormDisplayed" class="bg-white depth-1 mb-3 px-3 py-3 print-none" no-gutters>
    <b-col>
      <b-card class="mass-edit-form">
        <template v-slot:header>
          <h2>Edit percentages for selected rows</h2>
          <b-button class="btn-close" @click="onClose">&#x2716;</b-button>
        </template>

        <b-row no-gutters>
          <b-col class="col-12">
            <b-table
              :items="rows"
              :fields="fieldValues"
            >
              <template v-slot:thead-top>
                <b-tr>
                  <b-th :colspan="persons.length" class="text-center">
                    {{ $t(translationPath + 'table.should_pay_by') }}
                  </b-th>
                  <b-th :colspan="persons.length" class="text-center">
                    {{ $t(translationPath + 'table.paid_by') }}
                  </b-th>
                </b-tr>
              </template>

              <template v-for="(person, index) in persons" :slot='"cell(person_"+person.id+"_should_pay)"' slot-scope='row'>
                <percentage-input
                  v-model="row.item['person_'+person.id+'_should_pay']"
                  :key="index"
                  :value="row.value ? row.value : 0"
                  :inputId="'person_'+person.id+'_should_pay'"
                  :inputClass="shouldPayInputValidClass"
                  @input="(val) => onShouldPayInput(row.item, person.id, val)"
                ></percentage-input>
              </template>
              <template v-for="(person, index) in persons" :slot='"cell(person_"+person.id+"_paid)"' slot-scope='row'>
                <percentage-input
                  v-model="row.item['person_'+person.id+'_paid']"
                  :key="index"
                  :value="row.value ? row.value : 0"
                  :inputId="'person_'+person.id+'_paid'"
                  :inputClass="paidInputValidClass"
                  @input="(val) => onPaidInput(row.item, person.id, val)"
                ></percentage-input>
              </template>
            </b-table>
          </b-col>
          <b-col class="col-12 col-md-6">
            <b-form-group
              label-for="set_values_for"
              label-cols-lg="4"
              label-align-lg="right"
              required
              label-class="pr-4 text-black"
            >
              <template slot="label">{{ $t(translationPath + 'set_values_for') }}</template>
              <b-form-select id="set_values_for"
                v-model="applyFor"
                :options="applyforOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="col-12 col-md-6">
            <b-form-group
              label-for="category_id"
              label-cols-lg="4"
              label-align-lg="right"
              required
              label-class="pr-4 text-black"
            >
              <template slot="label">{{ $t('common.' + (currentUser.strict_accounting_mode ? 'account' : 'category')) }}</template>
              <b-form-select id="category_id"
                v-model="accountId"
                :options="accountDeopdownOptions"
                :disabled="applyFor !== 'selected' && applyFor !== 'selected_offset'"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-right mt-3">
          <b-col>
            <b-button type="submit" variant="primary" class="btn-sm mx-2" @click="onApply(false)" v-if="applyFor !== 'selected'">{{$t(translationPath + 'apply')}}</b-button>
            <b-button type="submit" variant="primary" class="btn-sm mx-2" @click="onApply(true)">{{$t(translationPath + 'apply_selected')}}</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import SplitExpensesTableMixin from './SplitExpensesTableMixin'
import PercentageInput from '@/components/common/PercentageInput'
import { mapState } from 'vuex'

export default {
  name: 'SplitExpensesMassEditPercentages',
  components: { PercentageInput },
  mixins: [SplitExpensesTableMixin],
  props: {
    isDisplayed: {
      type: Boolean,
      default: false
    },
    persons: {
      type: Array,
      default: () => []
    },
    accountOptions: {
      type: Array,
      default: () => []
    },
    offsetAccountOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isFormDisplayed: false,
      rows: [],
      translationPath: 'reports.performance.split_expenses_report.tab.split_expenses.',
      showValidationClass: false,
      applyFor: 'all',
      accountId: null
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    fieldValues () {
      const fields = []

      this.persons.map((person) => {
        fields.push({
          key: 'person_' + person.id + '_should_pay',
          label: this.fullName(person),
          class: 'td-percentage'
        })
      })
      this.persons.map((person) => {
        fields.push({
          key: 'person_' + person.id + '_paid',
          label: this.fullName(person),
          class: 'td-percentage'
        })
      })

      return fields
    },
    paidInputValidClass () {
      if (!this.showValidationClass) {
        return ''
      }

      let sum = 0
      this.persons.map((person) => {
        sum += parseInt(this.rows[0]['person_' + person.id + '_paid'])
      })

      if (sum === 100) {
        return 'is-valid'
      }
      return 'is-invalid'
    },
    shouldPayInputValidClass () {
      if (!this.showValidationClass) {
        return ''
      }

      let sum = 0
      this.persons.map((person) => {
        sum += parseInt(this.rows[0]['person_' + person.id + '_should_pay'])
      })

      if (sum === 100) {
        return 'is-valid'
      }
      return 'is-invalid'
    },
    applyforOptions () {
      return [
        { text: this.$t(this.translationPath + (this.currentUser.strict_accounting_mode ? 'all_expense_accounts' : 'all_expense_categories')), value: 'all' },
        { text: this.$t(this.translationPath + (this.currentUser.strict_accounting_mode ? 'selected_account' : 'selected_category')), value: 'selected' },
        { text: this.$t(this.translationPath + (this.currentUser.strict_accounting_mode ? 'selected_offset_account' : 'selected_offset_category')), value: 'selected_offset' }
        // { text: this.$t(this.translationPath + 'checked_rows'), value: 'checked' }
      ]
    },
    accountDeopdownOptions () {
      switch (this.applyFor) {
        case 'selected':
          return this.accountOptions
        case 'selected_offset':
          return this.offsetAccountOptions
        default:
          return []
      }
    }
  },
  methods: {
    onClose () {
      this.isFormDisplayed = false
      this.showValidationClass = false
      this.$emit('hide')
    },
    onApply (onlySelected) {
      if (!this.validate()) {
        this.$bvToast.toast(this.$t(this.translationReportPath + 'toast.percentages_not_valid'), {
          title: this.$t(this.translationReportPath + 'title'),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
        return false
      }

      const data = {
        applyFor: this.applyFor,
        onlySelected: onlySelected,
        accountId: this.accountId,
        percentages: []
      }
      this.persons.map((person) => {
        data.percentages.push({
          id: person.id,
          should_pay: this.rows[0]['person_' + person.id + '_should_pay'],
          paid: this.rows[0]['person_' + person.id + '_paid']
        })
      })

      this.$emit('apply', data)
      this.onClose()
    },
    onShouldPayInput (item, personId, val) {
      if (this.persons.length === 2) {
        let otherPersonId = 0
        this.persons.map((person) => {
          if (person.id !== personId) {
            otherPersonId = person.id
          }
        })
        item['person_' + otherPersonId + '_should_pay'] = 100 - val
      }
    },
    onPaidInput (item, personId, val) {
      if (this.persons.length === 2) {
        let otherPersonId = 0
        this.persons.map((person) => {
          if (person.id !== personId) {
            otherPersonId = person.id
          }
        })
        item['person_' + otherPersonId + '_paid'] = 100 - val
      }
    },
    fillForm () {
      const row = {}

      const totalDefaultPercentage = this.persons.reduce((a, b) => a + b.default_expense_percentage, 0)
      this.persons.map((person, index) => {
        if (totalDefaultPercentage === 100) {
          row['person_' + person.id + '_should_pay'] = person.default_expense_percentage
        } else if (this.persons.length === 1 && totalDefaultPercentage !== 100) {
          row['person_' + person.id + '_should_pay'] = 100
        } else if (this.persons.length > 1 && totalDefaultPercentage !== 100 && index === 0) {
          row['person_' + person.id + '_should_pay'] = person.default_expense_percentage
        } else if (this.persons.length > 1 && totalDefaultPercentage !== 100 && index === 1) {
          row['person_' + person.id + '_should_pay'] = 100 - this.persons[0].default_expense_percentage
        } else {
          row['person_' + person.id + '_should_pay'] = 0
        }

        row['person_' + person.id + '_paid'] = 0
      })

      this.rows.push(row)
    },
    validate () {
      this.showValidationClass = true
      let shouldPaySum = 0
      let paidSum = 0

      this.persons.map((person) => {
        shouldPaySum += parseInt(this.rows[0]['person_' + person.id + '_should_pay'])
        paidSum += parseInt(this.rows[0]['person_' + person.id + '_paid'])
      })

      if (shouldPaySum === 100 && paidSum === 100) {
        return true
      }
      return false
    }
  },
  watch: {
    isDisplayed (newVal) {
      this.isFormDisplayed = newVal
    },
    applyFor (newVal) {
      if (newVal === 'selected') {
        this.accountId = this.accountOptions[0] ? this.accountOptions[0].value : null
      } else if (newVal === 'selected_offset') {
        this.accountId = this.offsetAccountOptions[0] ? this.offsetAccountOptions[0].value : null
      }
    }
  },
  created () {
    this.isFormDisplayed = this.isDisplayed
    this.fillForm()
  }
}
</script>

<style lang="scss">
.split-expenses-report .mass-edit-form {
  .btn-close {
    color: #fff;
    padding: 0;
    position: absolute;
    right: -10px;
    top: -10px;
    border: 1px solid #c5c5c5;
    background: #c5c5c5;
    border-radius: 60px;
    width: 26px;
    text-align: center;
  }
  tr, th, td {
    border: none;
  }
}
</style>
