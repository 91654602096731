<template>
  <b-container fluid class="split-expenses-table-wrapper px-0">
    <b-row class="" no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPath + 'title') }}</h2>
          </template>

          <b-col class="col-md-8">
            <p>{{ $t( translationPath + 'description01') }}</p>
            <p>{{ $t( translationPath + 'description02') }}</p>
            <p>{{ $t( translationPath + 'description03') }} <b-link href="https://forum.spirecta.se/t/fordela-utgifter-sinsemellan-for-dig-med-uppdelad-ekonomi/186" target="_blank">{{ $t('common.read_more') }} →</b-link></p>
          </b-col>

          <b-row no-gutters class="print-none">
            <b-col cols="12" class="float-left px-0 my-2 actionButtons">
              <b-button v-if="rows.length && apiData !== null && apiData.persons.length > 1" variant="outline-primary" class="btn-sm mr-2" @click="onMassEditClick"><i class="flaticon solid edit-3 mr-2"></i>{{$t(translationPath + 'mass_edit')}}</b-button>
            </b-col>
          </b-row>

          <split-expenses-mass-edit-percentages v-if="apiData !== null && this.currentCOA.locale"
            :is-displayed="displayMassEditForm"
            :persons="apiData.persons"
            :accountOptions="accountOptions"
            :offsetAccountOptions="offsetAccountOptions"
            @hide="() => displayMassEditForm = false"
            @apply="onMassEditApply"
          ></split-expenses-mass-edit-percentages>

          <b-col class="px-0 mb-5">
            <template v-if="apiData !== null && apiData.persons.length > 1 || apiData === null">
            <b-table class="spirecta-simple-table split-expenses-table" show-empty hover responsive striped foot-clone
              stacked="md"
              ref="splitTable"
              :items="rows"
              :fields="fieldValues"
              :current-page="currentPage"
              :per-page="perPage"
              :busy="busy || !loaded"
              :empty-text="$t(translationPath + 'table.empty_text',{period_start: loaded ? this.period.start : '', period_end: loaded ? this.period.end : ''}) "
            >

              <template v-slot:table-busy>
                <loader/>
              </template>

              <template v-slot:thead-top>
                <b-tr>
                  <b-th colspan="6">&nbsp;</b-th>
                  <b-th v-if="apiData !== null" :colspan="apiData.persons.length + 1" class="text-center">
                    <small>{{ $t(translationPath + 'table.should_pay_by') }}</small>
                  </b-th>
                  <b-th v-if="apiData !== null" :colspan="apiData.persons.length + 1" class="text-center">
                    <small>{{ $t(translationPath + 'table.paid_by') }}</small>
                  </b-th>
                  <b-th colspan="2" v-if="apiData !== null">&nbsp;</b-th>
                </b-tr>
              </template>

              <template v-slot:head(is_checked)>
                <b-form-checkbox
                  v-model="allSelected"
                  :indeterminate="indeterminate"
                  @change="toggleAll"
                >
                </b-form-checkbox>
                <!--<b-form-checkbox v-model="allSelected" @change="onAllSelectedChange"></b-form-checkbox>-->
              </template>
              <template v-slot:foot(is_checked)>
                <b-form-checkbox
                  v-model="allSelected"
                  :indeterminate="indeterminate"
                  @change="toggleAll"
                >
                </b-form-checkbox>
                <!--<b-form-checkbox class="ml-2" v-model="allSelected" @change="onAllSelectedChange"></b-form-checkbox>-->
              </template>

              <template v-slot:cell(title)="row">
                <b-link :to="'/transactions/view/transaction/' + row.item.id">{{ row.value }}</b-link>
              </template>

              <template v-slot:cell(amount)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
              </template>

              <template v-slot:cell(account)="row">
                <small><b-link :to="'/reports/performance/accounts/'+row.item.account_id+'/view'">{{ row.item.account }}</b-link></small>
              </template>

              <template v-slot:cell(paid_from)="row">
                <small><b-link :to="'/reports/performance/accounts/'+row.item.paid_from_account_id+'/view'">{{ row.item.paid_from }}</b-link></small>
              </template>

              <template v-slot:cell(should_pay_sum)="row">
                <percentage-input
                  v-model="row.item.should_pay_sum"
                  :value="row.value"
                  :inputId="'transaction_'+row.item.id+'_should_pay_sum'"
                  :inputClass="row.value > 100 && row.item.is_checked ? 'is-invalid' : ''"
                  :disabled="true"
                  :max="1000"
                ></percentage-input>
              </template>
              <template v-slot:cell(paid_sum)="row">
                <percentage-input
                  v-model="row.item.paid_sum"
                  :value="row.value"
                  :inputId="'transaction_'+row.item.id+'_paid_sum'"
                  :inputClass="row.value > 100 && row.item.is_checked ? 'is-invalid' : ''"
                  :disabled="true"
                  :max="1000"
                ></percentage-input>
              </template>

              <template v-for="(person, index) in (apiData !== null ? apiData.persons : [])" :slot='"cell(person_"+person.id+"_should_pay)"' slot-scope='row'>
                <percentage-input
                  v-model="row.item['person_'+person.id+'_should_pay']"
                  :key="index"
                  :value="row.value ? row.value : 0"
                  :inputId="'transaction_'+row.item.id+'_person_'+person.id+'_should_pay'"
                  @input="(val) => onShouldPayInput(row.item, person.id, val)"
                ></percentage-input>
              </template>
              <template v-for="(person, index) in (apiData !== null ? apiData.persons : [])" :slot='"cell(person_"+person.id+"_paid)"' slot-scope='row'>
                <percentage-input
                  v-model="row.item['person_'+person.id+'_paid']"
                  :key="index"
                  :value="row.value ? row.value : 0"
                  :inputId="'transaction_'+row.item.id+'_person_'+person.id+'_paid'"
                  @input="(val) => onPaidInput(row.item, person.id, val)"
                  :tabindex="row.item.tabindex_start + index"
                ></percentage-input>
              </template>

              <template v-slot:cell(amount_to_be_paid_per_person)="row">
                {{ amountPerPersonText(row.item, '_should_pay') }}
              </template>

              <template v-slot:cell(amount_paid_per_person)="row">
                {{ amountPerPersonText(row.item, '_paid') }}
              </template>

              <template v-slot:cell(ok_btn)="row">
                <b-button variant="success" class="btn-sm" :tabindex="row.item.tabindex_end" :disabled="saveDisabled || isOkBtnDisabled(row.item)" @click="() => onSave(row.item)">OK</b-button>
              </template>

              <template v-slot:cell(is_checked)="row">
                <b-form-checkbox-group v-model="selected">
                  <b-form-checkbox
                    :value="row.item"
                    @change="(values) => selectCheckBoxRow(values, row.index)"
                  >
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </template>
            </b-table>
            <pagination v-if="rows.length" :totalRows="rows.length" :currentPage.sync="currentPage" :perPage.sync="perPage"></pagination>
            <b-row v-if="rows.length" class="mb-5 mt-4">
              <b-col class="pt-4 pt-md-1">
                <b-button
                  type="submit"
                  variant="primary"
                  class="float-right ml-3 px-4 btn-save"
                  :disabled="saveDisabled"
                  @click="() => onSave()"
                >{{ $t(translationPath + 'ok_all_rows') }}</b-button>
              </b-col>
            </b-row>
            </template>
            <template v-else>
              <b-alert variant="warning" :show="true" class="col-lg-8 text-center">
                {{ $t(translationPath + 'no_persons') }} <b-link to="/settings/chart-of-account">{{ $t(translationPath + 'no_persons_change_here') }}</b-link>
              </b-alert>
            </template>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import pagination from '@/components/common/pagination'
import PercentageInput from '@/components/common/PercentageInput'
import Loader from '@/components/common/Loader'
import formatAmount from '../../../../assets/filters/formatAmount'
import SplitExpensesTableMixin from './SplitExpensesTableMixin'
import SplitExpensesMassEditPercentages from './SplitExpensesMassEditPercentages'

export default {
  name: 'SplitExpensesTableSplitExpenses',
  props: ['api-data', 'loaded', 'fillMode', 'period'],
  components: { Loader, PercentageInput, pagination, SplitExpensesMassEditPercentages },
  mixins: [SplitExpensesTableMixin],
  filters: { formatAmount },
  data () {
    return {
      translationPath: 'reports.performance.split_expenses_report.tab.split_expenses.',
      busy: true,
      currentPage: 1,
      perPage: 50,
      saveDisabled: true,
      indeterminate: false,
      rows: [],
      allSelected: false,
      selectedValue: false,
      selected: [],
      displayMassEditForm: false
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    fieldValues () {
      const fields = [
        { key: 'is_checked', label: '' },
        { key: 'date', label: this.$t('common.date'), class: 'td-date' },
        { key: 'title', label: this.$t('common.title'), class: 'td-title' },
        { key: 'amount', label: this.$t('common.amount'), class: 'td-amount text-right text-nowrap' },
        { key: 'account', label: this.$t('common.category'), class: 'td-account' },
        { key: 'paid_from', label: this.$t(this.translationPath + 'paid_from'), class: 'td-paid_from_account' }
      ]

      if (this.apiData === null) {
        return fields
      }

      for (const j in this.apiData.persons) {
        fields.push({
          key: 'person_' + this.apiData.persons[j].id + '_should_pay',
          label: this.fullName(this.apiData.persons[j], true),
          class: 'td-percentage'
        })
      }
      fields.push({
        key: 'amount_to_be_paid_per_person',
        label: this.$t(this.translationPath + 'table.amount_to_be_paid_per_person'),
        class: 'td-amount-to-be-paid-per-person text-right'
      })

      for (const j in this.apiData.persons) {
        fields.push({
          key: 'person_' + this.apiData.persons[j].id + '_paid',
          label: this.fullName(this.apiData.persons[j], true),
          class: 'td-percentage'
        })
      }

      fields.push({ key: 'amount_paid_per_person', label: this.$t(this.translationPath + 'table.amount_paid_per_person'), class: 'text-right' })
      fields.push({ key: 'ok_btn', label: '', class: 'td-ok_btn' })

      return fields
    },
    accountOptions () {
      const options = []
      let prevId = 0
      for (const j in this.apiData.transactions) {
        if (this.apiData.transactions[j].expense_account_id === prevId) {
          continue
        }
        prevId = this.apiData.transactions[j].expense_account_id
        options.push({
          value: this.apiData.transactions[j].expense_account_id,
          text: this.apiData.transactions[j].expense_account_title
        })
      }

      options.sort((a, b) => a.text < b.text ? -1 : 1)
      return options.filter((item, index, self) => {
        const findIndex = self.findIndex((el) => el.text === item.text && el.value === item.value)
        return findIndex === index
      })
    },
    offsetAccountOptions () {
      const options = []
      let prevId = 0
      for (const j in this.apiData.transactions) {
        if (this.apiData.transactions[j].paid_from_account_id === prevId) {
          continue
        }
        prevId = this.apiData.transactions[j].paid_from_account_id
        options.push({
          value: this.apiData.transactions[j].paid_from_account_id,
          text: this.apiData.transactions[j].paid_from_account_title
        })
      }

      options.sort((a, b) => a.text < b.text ? -1 : 1)
      return options.filter((item, index, self) => {
        const findIndex = self.findIndex((el) => el.text === item.text && el.value === item.value)
        return findIndex === index
      })
    }
  },
  methods: {
    toggleAll (value) {
      this.selected = []
      if (this.allSelected === false && this.selectedValue === true && this.indeterminate === true && value === true) {
        this.$nextTick(function () {
          this.toggleAll(false)
        })
        return
      }
      if (value === false) {
        this.selectedValue = false
        return
      }
      if (this.selectedValue === true & value === true) {
        this.selectedValue = false
        this.allSelected = false
        return
      }
      this.selectedValue = true
      const validLowerrange = this.currentPage * this.perPage
      const validUpperRange = validLowerrange - this.perPage
      this.rows.forEach((elem, index) => {
        const selectedIndex = [...this.selected].map((valueItem) => valueItem.id)
        if (selectedIndex.indexOf(elem.id) === -1 && index >= validUpperRange && index <= validLowerrange - 1) {
          this.$nextTick(function () {
            this.selected.push(elem)
          })
        }
      })
    },
    selectCheckBoxRow (value, index) {
      if (value) {
        this.$refs.splitTable.selectRow(index)
      } else {
        this.$refs.splitTable.unselectRow(index)
      }
    },
    fillTable (mode) {
      if (this.apiData === null || !this.currentCOA.locale) {
        return false
      }

      this.mode = mode || 'hard'
      this.saveDisabled = true
      if (mode === 'hard' || !this.rows.length) {
        this.busy = true
        this.rows = []
        for (const transactionId in this.apiData.transactions) {
          const transaction = this.apiData.transactions[transactionId]
          const row = {
            id: transactionId,
            is_checked: false,
            title: transaction.title,
            date: transaction.date,
            amount: transaction.amount,
            account: transaction.expense_account_title,
            account_id: transaction.expense_account_id,
            paid_from: transaction.paid_from_account_title,
            paid_from_account_id: transaction.paid_from_account_id,
            should_pay_sum: 0,
            paid_sum: 0
          }

          const totalDefaultPercentage = this.apiData.persons.reduce((a, b) => a + b.default_expense_percentage, 0)
          const persons = this.apiData.persons.length
          this.apiData.persons.map((person, index) => {
            if (totalDefaultPercentage === 100) {
              row['person_' + person.id + '_should_pay'] = person.default_expense_percentage
            } else if (persons === 1 && totalDefaultPercentage !== 100) {
              row['person_' + person.id + '_should_pay'] = 100
            } else if (persons > 1 && totalDefaultPercentage !== 100 && index === 0) {
              row['person_' + person.id + '_should_pay'] = person.default_expense_percentage
            } else if (persons > 1 && totalDefaultPercentage !== 100 && index === 1) {
              row['person_' + person.id + '_should_pay'] = 100 - this.apiData.persons[0].default_expense_percentage
            } else {
              row['person_' + person.id + '_should_pay'] = 0
            }

            row['person_' + person.id + '_paid'] = 0
          })

          if (transaction.person_percentages) {
            for (const j in transaction.person_percentages) {
              const person = transaction.person_percentages[j]
              row['person_' + person.person_id + '_should_pay'] = parseFloat(person.percentage_should_pay)
              row['person_' + person.person_id + '_paid'] = parseFloat(person.percentage_paid)
            }
          }
          this.apiData.persons.map((person) => {
            row.should_pay_sum += row['person_' + person.id + '_should_pay']
            row.paid_sum += row['person_' + person.id + '_paid']
          })
          this.rows.push(row)
        }
        this.rows.sort((a, b) => a.date < b.date ? -1 : 1)
      }

      let n = 1
      this.rows.map(row => {
        row.tabindex_start = n
        n += (this.apiData.persons.length + 1)
        row.tabindex_end = n - 1
      })

      this.busy = false
      this.saveDisabled = false
    },
    amountPerPersonText (item, suffix) {
      let str = ''
      this.apiData.persons.map((person) => {
        let personAmount = item.amount * (item['person_' + person.id + suffix] / 100)
        personAmount = formatAmount(personAmount, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, false, 0)
        str += personAmount + ' / '
      })
      return str.substr(0, str.length - 2)
    },
    isOkBtnDisabled (item) {
      if (item.paid_sum !== 100 || item.should_pay_sum !== 100) {
        return true
      }
      return false
    },
    onShouldPayInput (item, personId, val) {
      item.is_checked = true

      if (this.apiData.persons.length === 2) {
        let otherPersonId = 0
        this.apiData.persons.map((person) => {
          if (person.id !== personId) {
            otherPersonId = person.id
          }
        })
        item['person_' + otherPersonId + '_should_pay'] = 100 - val
      }

      item.should_pay_sum = 0
      this.apiData.persons.map((person) => {
        item.should_pay_sum += item['person_' + person.id + '_should_pay']
      })
    },
    onPaidInput (item, personId, val) {
      item.is_checked = true

      if (this.apiData.persons.length === 2) {
        let otherPersonId = 0
        this.apiData.persons.map((person) => {
          if (person.id !== personId) {
            otherPersonId = person.id
          }
        })
        item['person_' + otherPersonId + '_paid'] = 100 - val
      }

      item.paid_sum = 0
      this.apiData.persons.map((person) => {
        item.paid_sum += item['person_' + person.id + '_paid']
      })
    },
    onSave (row) {
      row = row || false
      this.saveDisabled = true
      let rowsChecked = null
      if (row) {
        row.is_checked = true
        rowsChecked = [row]
      } else {
        rowsChecked = this.rows.filter((row) => row.is_checked)
      }

      if (!rowsChecked.length) {
        this.$bvToast.toast(this.$t(this.translationReportPath + 'toast.rows_are_not_selected'), {
          title: this.$t(this.translationReportPath + 'title'),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
        this.saveDisabled = false
        return false
      }

      const isValid = !rowsChecked.some((row) => row.paid_sum !== 100 || row.should_pay_sum !== 100)
      if (!isValid) {
        this.$bvToast.toast(this.$t(this.translationReportPath + 'toast.percentages_not_valid'), {
          title: this.$t(this.translationReportPath + 'title'),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
        this.saveDisabled = false
        return false
      }

      const data = {}
      rowsChecked.map((transaction) => {
        data[transaction.id] = {}
        this.apiData.persons.map((person) => {
          data[transaction.id][person.id] = {
            percentage_paid: transaction['person_' + person.id + '_paid'],
            percentage_should_pay: transaction['person_' + person.id + '_should_pay']
          }
        })
      })

      axios.post(`${process.env.VUE_APP_ROOT_API}/transactions/person-percentages-multiple`, { data: data })
        .then((response) => {
          this.$bvToast.toast(this.$t(this.translationReportPath + 'toast.percentages_updated_success'), {
            title: this.$t(this.translationReportPath + 'title'),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })

          if (row) {
            this.rows = this.rows.filter((item) => item.id !== row.id)
          } else {
            this.rows = this.rows.filter((item) => !item.is_checked)
          }

          this.$emit('data-updated')
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationReportPath + 'toast.percentages_updated_fail'), {
            title: this.$t(this.translationReportPath + 'title'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .then(() => {
          this.saveDisabled = false
        })
    },
    onAllSelectedChange (val) {
      this.rows.map((row) => {
        row.is_checked = val
      })
      if (!val) {
        this.displayMassEditForm = false
      }
    },
    onItemSelectedChange (val) {
      let totalSelected = this.rows.filter((row) => row.is_checked).length

      if (val) {
        totalSelected++
      } else {
        totalSelected--
      }

      if (this.rows.length === totalSelected) {
        this.allSelected = true
      } else {
        this.allSelected = false
      }

      if (!totalSelected) {
        this.displayMassEditForm = false
      }
    },
    onMassEditClick () {
      this.displayMassEditForm = true
    },
    onMassEditApply (data) {
      console.log('data:', data)
      let targetRows = []
      switch (data.applyFor) {
        /* case 'checked':
          targetRows = this.rows.filter((row) => row.is_checked)
          break */
        case 'selected':
          targetRows = this.selected.filter((row) => row.account_id === data.accountId)
          break
        case 'selected_offset':
          targetRows = this.rows.filter((row) => row.paid_from_account_id === data.accountId)
          break
        case 'all':
        default:
          targetRows = this.rows.filter((row) => row.id)
      }
      if (data.onlySelected) {
        const selectedIndex = [...this.selected].map((valueItem) => valueItem.id)
        targetRows = targetRows.filter(row => selectedIndex.indexOf(row.id) > -1)
      }

      targetRows.map((row) => {
        row.should_pay_sum = 0
        row.paid_sum = 0
        data.percentages.map((person) => {
          row['person_' + person.id + '_should_pay'] = person.should_pay
          row['person_' + person.id + '_paid'] = person.paid
          row.should_pay_sum += person.should_pay
          row.paid_sum += person.paid
          // row.is_checked = false
        })
      })

      this.toggleAll(false)
      this.$refs.splitTable.clearSelected()
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.fillTable(this.fillMode)
      }
    },
    selected: function (newVal) {
      if (newVal.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newVal.length === this.$refs.splitTable.items.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
    apiData: {
      deep: true,
      handler () {
        this.fillTable(this.fillMode)
      }
    },
    perPage: {
      handler: function (value) {
        if (value) {
          this.toggleAll(false)
        }
      }
    },
    currentPage: {
      handler: function (value) {
        if (value) {
          this.toggleAll(false)
        }
      }
    }
  },
  created () {
    this.fillTable(this.fillMode)
  }
}
</script>
<style lang="scss">
.split-expenses-table{
  thead tr th{border-top:0;}
  td.td-date{white-space: nowrap;}
  td.td-percentage{width: 80px;}

  tr:nth-child(2)
  {
    th.td-paid_from_account,
    th.td-amount-to-be-paid-per-person{
      border-right:1px solid black;padding-right: 10px;
    }
  }

  td.td-paid_from_account,
  td.td-amount-to-be-paid-per-person
  {border-right:1px solid black;padding-right: 10px;}

  .td-ok_btn{
  border-left:1px solid black;
  }
}
</style>
