<template>
  <b-container fluid class="split-expenses-summary-detailed-table px-0">
    <b-row class="" no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPath + (currentUser.strict_accounting_mode ? 'title_accounts' : 'title_categories')) }}</h2>
          </template>

          <b-col class="col-md-8">
            <p>{{ $t( translationPath + (currentUser.strict_accounting_mode ? 'description_accounts' : 'description_categories')) }}</p>
          </b-col>

          <b-col class="px-0 mb-5">
            <b-table class="spirecta-simple-table split-expenses-distributed-summary-table" show-empty hover responsive striped foot-clone
              stacked="md"
              :items="rows"
              :fields="fieldValues"
              :busy="busy || !loaded"
            >

              <template v-slot:table-busy>
                <loader/>
              </template>

              <template v-slot:head(title)="row">
                {{ $t(translationPath + row.label) }}
              </template>

              <template v-slot:cell(title)="row">
                <template v-if="row.item.belongs_to === 'total-row'">
                  {{ $t('common.total') }}
                </template>
                <template v-else>
                  <b-link :to="'/reports/performance/account-groups/' + row.item.id">{{ row.value }}</b-link>
                </template>
              </template>

              <template v-for="(person, index) in (apiData !== null ? apiData.persons : [])" :slot='"cell(person_"+person.id+"_should_pay)"' slot-scope='row'>
                <span :key="index">
                  {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
                </span>
              </template>

              <template v-slot:cell(total)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
              </template>
            </b-table>
          </b-col>
        </b-card>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import Loader from '@/components/common/Loader'
import SplitExpensesTableMixin from './SplitExpensesTableMixin'

export default {
  name: 'SplitExpensesDistributedExpensesTableAccountGroups',
  props: ['api-data', 'loaded', 'accounts'],
  components: { Loader },
  mixins: [SplitExpensesTableMixin],
  filters: { formatAmount },
  data () {
    return {
      translationPath: 'reports.performance.split_expenses_report.tab.distributed_expenses.account_groups.',
      busy: true,
      rows: []
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    fieldValues () {
      const fields = [
        { key: 'title', label: this.currentUser.strict_accounting_mode ? 'account_groups' : 'category_groups' }
      ]

      if (this.apiData === null) {
        return fields
      }

      this.apiData.persons.map((person) => {
        fields.push({
          key: 'person_' + person.id + '_should_pay',
          label: this.$t(this.translationPath + 'person_should_pay', { person_name: this.fullName(person, true) }),
          class: 'text-right'
        })
        fields.push({
          key: 'person_' + person.id + '_should_pay_percentage',
          label: this.$t(this.translationPath + '_should_pay_percentage', { person_name: this.fullName(person, true) }),
          class: 'text-right'
        })
      })

      fields.push({ key: 'total', label: this.$t(this.translationPath + 'total'), class: 'text-right td-total' })
      return fields
    }
  },
  methods: {
    fillTable () {
      if (this.apiData === null || !this.currentCOA.locale) {
        return false
      }

      this.busy = true
      this.rows = []

      const totalRow = { title: '', belongs_to: 'total-row' }
      this.apiData.persons.map((person) => {
        totalRow['person_' + person.id + '_should_pay'] = 0
        totalRow['person_' + person.id + '_should_pay_percentage'] = 0
        totalRow.total = 0
      })

      const accountGroups = {}
      for (const transactionId in this.apiData.transactions_balanced) {
        const transaction = this.apiData.transactions_balanced[transactionId]
        if (!transaction.is_person_percentage_set) {
          continue
        }

        if (!accountGroups[transaction.expense_account_group_id]) {
          accountGroups[transaction.expense_account_group_id] = {
            id: transaction.expense_account_group_id,
            title: transaction.expense_account_group_title
          }
          this.apiData.persons.map((person) => {
            accountGroups[transaction.expense_account_group_id]['person_' + person.id + '_should_pay'] = 0
            accountGroups[transaction.expense_account_group_id]['person_' + person.id + '_should_pay_percentage'] = 0
            accountGroups[transaction.expense_account_group_id].total = 0
          })
        }

        if (transaction.person_percentages) {
          accountGroups[transaction.expense_account_group_id].total += transaction.amount

          transaction.person_percentages.map((person) => {
            accountGroups[transaction.expense_account_group_id]['person_' + person.person_id + '_should_pay'] += person.amount_should_pay
            accountGroups[transaction.expense_account_group_id]['person_' + person.person_id + '_should_pay_percentage'] = (100 * accountGroups[transaction.expense_account_group_id]['person_' + person.person_id + '_should_pay'] / accountGroups[transaction.expense_account_group_id].total).toFixed(2) + ' %'

            totalRow['person_' + person.person_id + '_should_pay'] += person.amount_should_pay
            totalRow.total += person.amount_should_pay
          })
        }
      }

      for (const j in accountGroups) {
        this.rows.push(accountGroups[j])
      }
      this.rows.sort((a, b) => a.title < b.title ? -1 : 1)

      // Calc totals
      this.apiData.persons.map((person) => {
        totalRow['person_' + person.id + '_should_pay_percentage'] = ((totalRow['person_' + person.id + '_should_pay'] / totalRow.total) * 100).toFixed(2) + ' %'
      })
      this.rows.push(totalRow)

      this.busy = false
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.fillTable()
      }
    },
    apiData: {
      deep: true,
      handler () {
        this.fillTable()
      }
    },
    accounts: {
      deep: true,
      handler () {
        this.fillTable()
      }
    }
  },
  created () {
    this.fillTable()
  }
}
</script>
<style lang="scss">
.split-expenses-distributed-summary-table
{
  td:nth-child(4),th:nth-child(4){
    border-left:1px solid black;
    border-right:0;
  }
  td:nth-child(5),th:nth-child(5){
    border-right:1px solid black;
  }
}
</style>
