<template>
  <div class="split-expenses-report" :class="'tab-' + activeTab">
    <b-container class="main-gradient light" fluid>
      <b-container class="main-gradient-header-wrapper mb-4">
        <!-- Breadcrumbs, tabs, title -->
        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters>
            <b-breadcrumb
              :items="breadcrumbItems"
              class="breadcrumb-light"
            ></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col class="col-12 col-md-6 col-lg-7 col-xl-9">
            <h1 class="text-left">{{ $t(translationPath + "title") }}</h1>
          </b-col>
          <b-col class="col-12 col-md-6 col-lg-5 col-xl-3">
            <period-selector
              default-period-type="last-month"
              :show-predefined-periods="true"
              :show-resolution="true"
              :allowed-resolutions="['month', 'year']"
              default-resolution="month"
              @period-selected="onPeriodSelected"
            />
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper" no-gutters>
          <b-col no-gutters>
            <split-expenses-submenu
              :disabled="apiData === null || apiData !== null && apiData.persons.length < 2"
              :activeTab="activeTab"
              @tab-switched="onTabSwitched"
            ></split-expenses-submenu>
          </b-col>
        </b-row>
      </b-container>

      <b-row class="main-gradient-chart-wrapper">
        <div class="depth-graph_chart-wrapper pb-5">
          <split-expenses-chart-summary
            v-if="activeTab === 'summary'"
            :api-data="apiData"
          ></split-expenses-chart-summary>
        </div>
      </b-row>
    </b-container>

    <b-container class="main-content-wrapper pt-0">
      <split-expenses-balance-expenses-table-wrapper
        v-if="activeTab === 'balance_expenses'"
        :api-data="apiData"
        :loaded="loaded"
        :period="period"
        :fillMode="lastLoadedDataMode"
        @data-updated="loadData('soft')"
      >
      </split-expenses-balance-expenses-table-wrapper>

      <split-expenses-distributed-expenses-table-wrapper
        v-if="activeTab === 'distributed_expenses'"
        :api-data="apiData"
        :loaded="loaded"
        :period="period"
        :fillMode="lastLoadedDataMode"
        @data-updated="loadData('soft')"
      >
      </split-expenses-distributed-expenses-table-wrapper>

      <split-expenses-table-split-expenses
        v-if="activeTab === 'split_expenses'"
        :api-data="apiData"
        :loaded="loaded"
        :period="period"
        :fillMode="lastLoadedDataMode"
        @data-updated="loadData('soft')"
      ></split-expenses-table-split-expenses>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import PeriodSelector from '@/components/common/PeriodSelector'
import SplitExpensesSubmenu from './SplitExpensesSubmenu'
import SplitExpensesTableSplitExpenses from './SplitExpensesTableSplitExpenses'
import SplitExpensesBalanceExpensesTableWrapper from './SplitExpensesBalanceExpensesTableWrapper'
import SplitExpensesDistributedExpensesTableWrapper from './SplitExpensesDistributedExpensesTableWrapper'
import titleMixins from '@/mixins/title'

export default {
  name: 'SplitExpenses',
  mixins: [titleMixins],
  components: {
    PeriodSelector,
    SplitExpensesSubmenu,
    SplitExpensesTableSplitExpenses,
    SplitExpensesBalanceExpensesTableWrapper,
    SplitExpensesDistributedExpensesTableWrapper
  },
  data () {
    return {
      activeTab: 'split_expenses',
      apiData: null,
      loaded: false,
      lastLoadedDataMode: 'hard',
      // softUpdateDisabled: true, // Remove after accepting updating with "recalculate" button
      expenseAccounts: {},
      period: {
        start: null,
        end: null
      },
      translationPath: 'reports.performance.split_expenses_report.'
    }
  },
  computed: {
    breadcrumbItems () {
      return [
        {
          text: this.$t('reports._common.reports_and_insights'),
          to: '/reports'
        },
        {
          text: this.$t('reports._common.performance_reports'),
          to: '/reports/performance'
        },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    }
  },
  methods: {
    onTabSwitched (tab) {
      this.activeTab = tab
    },
    onPeriodSelected (eventData) {
      this.period.start = eventData.sDateStart
      this.period.end = eventData.sDateEnd
      /* if (val.start_date) {
        this.period.start = val.start_date
      }
      if (val.end_date) {
        this.period.end = val.end_date
      } */
    },
    loadData (mode) {
      if (!this.period.start || !this.period.end) {
        return false
      }

      /** mode means how system should update data for user. So system receives data from api as usual
       * but gives decision about how update them to child components. 'hard' is default value can mean
       * show loader and rebuild tables. 'soft' can mean to not show loader and update values for each existed
       * row in table or even do not touch them (in case when save button is pressed: i.e. data are actual).
       */

      mode = mode || 'hard'
      /* if (mode === 'soft' && this.softUpdateDisabled) {
        return false // Disable soft update. Use "recalculate" button instead.
      } */

      this.loaded = mode === 'hard' ? false : this.loaded
      this.lastLoadedDataMode = mode
      // const startDate = moment(this.period.start).format('YYYY/MM/DD').toString()
      // const endDate = moment(this.period.end).format('YYYY/MM/DD').toString()
      axios
        .get(
          `${process.env.VUE_APP_ROOT_API}/reports/performance/split-expenses?start_date=${this.period.start}&end_date=${this.period.end}`
        )
        .then(response => {
          this.apiData = response.data.data
          this.loaded = mode === 'hard' ? true : this.loaded
        })
        .catch(error => {
          console.error(error)
        })
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    period: {
      deep: true,
      handler (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    // Commented out becouse we can pass needed params to period selector component. Delete if ok.
    /* const date = new Date()
    const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    const lastDay = new Date(date.getFullYear(), date.getMonth(), 0)

    this.period.start = firstDay.toLocaleDateString()
    this.period.end = lastDay.toLocaleDateString() */

    this.loadData()
  }
}
</script>

<style lang="scss">
.split-expenses-report {
  width: 100%;
  table {
    a {
      color: #000;
    }
  }
  .split-expenses-summary-table {
    tfoot {
      display: none;
    }
    tbody {

    }
  }
  .split-expenses-summary-detailed-table {
    tfoot {
      display: none;
    }
    tbody {
      tr:last-child {
        border-top: 1px solid #000;
        border-bottom: 2px solid #000;
        font-weight: bold;
      }
    }
  }
  .split-expenses-table {
    tfoot {
      display: none;
    }
    tr:first-child th {
      border-bottom: none;
      padding-bottom: 0px;
    }
    tr:last-child th {
      padding-top: 0px;
    }
  }
}
</style>
