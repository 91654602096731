<template>
  <div class="split-expenses-distributed-expenses-table-wrapper">
    <split-expenses-distributed-expenses-table-account-groups
      class="mt-4"
      :api-data="apiData"
      :loaded="loaded"
    ></split-expenses-distributed-expenses-table-account-groups>

    <split-expenses-distributed-expenses-table-accounts
      class="mt-4"
      :api-data="apiData"
      :loaded="loaded"
    ></split-expenses-distributed-expenses-table-accounts>

    <split-expenses-distributed-expenses-table-transactions
      class="mt-4"
      :api-data="apiData"
      :loaded="loaded"
      :period="period"
      @data-updated="onDataUpdated"
    >
    </split-expenses-distributed-expenses-table-transactions>
  </div>
</template>

<script>
import SplitExpensesDistributedExpensesTableAccountGroups from './SplitExpensesDistributedExpensesTableAccountGroups'
import SplitExpensesDistributedExpensesTableAccounts from './SplitExpensesDistributedExpensesTableAccounts'
import SplitExpensesDistributedExpensesTableTransactions from './SplitExpensesDistributedExpensesTableTransactions'

export default {
  name: 'SplitExpensesDistributedExpensesTableWrapper',
  props: {
    apiData: { default: [] },
    period: { default: false },
    loaded: { default: false }
  },
  components: {
    SplitExpensesDistributedExpensesTableAccountGroups,
    SplitExpensesDistributedExpensesTableAccounts,
    SplitExpensesDistributedExpensesTableTransactions
  },
  data () {
    return {
    }
  },
  computed: {},
  methods: {
    onDataUpdated () {
      this.$emit('data-updated')
    },
    onNeedApiUpdate () {
      this.$emit('need-api-update')
    }
  }
}
</script>

<style lang="scss">

</style>
